import React, { useState } from "react";
import { PageContentLayout, PageHeaderLayout } from "../../../shared/layout";
import { AreaChartOutlined } from "@ant-design/icons";
import { mainTitleIcon } from "../../../shared/globalStyling/style";
import { withRouter } from "react-router-dom";
import ExportButtons from "./components/ExportButtons";
import { Row } from "antd";
import DatePickerField from "../../../shared/charts/components/DatePickerField";
import moment from "moment";
import ReportingTab from "./tabs/ReportingTab";
import ChannelTabs from "./components/ChannelTabs";

const SeoDetailsView = ({
  name = "",
  exportData = false,
  location,
  keywordsReportKey,
  trafficReportKey,
  conversionsReportKey,
  linkAcquisitionData,
  referralsReportKey,
  domain,
  //Legacy Props
  isMultiChannel = false,
  googleReportKey = "",
  commerceKey = "",
  hasGoogle = false,
  hasCommerce = false,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("tab");

  //Title shown on browser tab
  document.title = `${name} SEO Details View`;

  //Report Data hook for export csv. Set on each tab change for different reports
  const [reportData, setReportData] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState("Custom");
  const [selectedDate, setSelectedDate] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);

  return (
    <React.Fragment>
      <PageHeaderLayout
        title={name}
        titleIcon={<AreaChartOutlined style={mainTitleIcon} />}
        rightContent={
          <ExportButtons
            exportData={exportData}
            name={name}
            currentTab={currentTab}
            reportData={reportData}
          />
        }
        centerContent={
          showDatePicker &&
          selectedDate && (
            <Row justify={"center"}>
              <DatePickerField
                value={selectedDate}
                allTimeDateRange={[moment("2020-01-01"), moment()]}
                dropDownValue={selectedDropdown}
                setCustomDateRange={setSelectedDate}
                setDropDownValue={setSelectedDropdown}
                has6MonDisables={false}
                showDropdown={true} // TODO: show dropdown
              />
            </Row>
          )
        }
      />
      <PageContentLayout>
        {trafficReportKey || conversionsReportKey || linkAcquisitionData ? (
          <ReportingTab
            domain={domain}
            hasKeywordRankings={keywordsReportKey ? true : false}
            keywordsReportKey={keywordsReportKey}
            trafficReportKey={trafficReportKey}
            conversionsReportKey={conversionsReportKey}
            linkAcquisitionData={linkAcquisitionData}
            referralsReportKey={referralsReportKey}
            setShowDatePicker={setShowDatePicker}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setSelectedDropdown={setSelectedDropdown}
            setReportData={setReportData}
          />
        ) : (
          //Channel Tabs is for Legacy Campaign
          <ChannelTabs
            isMultiChannel={isMultiChannel}
            googleReportKey={googleReportKey}
            commerceKey={commerceKey}
            hasGoogle={hasGoogle}
            hasCommerce={hasCommerce}
            setReportData={setReportData}
          />
        )}
      </PageContentLayout>
    </React.Fragment>
  );
};

export default withRouter(SeoDetailsView);
