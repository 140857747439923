import React from "react";
import { Modal } from "antd";

const TagInstallationInstructionsModal = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  return (
    <React.Fragment>
      <Modal
        title={"Tag Installation Instructions"}
        width={"50%"}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        centered
        footer={null}
      >
        <>
          <iframe
            src={`${process.env.REACT_APP_INTERNAL_MICROSERVICE_URL}/api/docs/html?filename=integrations%2Ftag-installation.md`}
            title={"Tag Installation Instructions"}
            width="100%"
            height="800px"
            style={{ border: "none" }}
          />
        </>
      </Modal>
    </React.Fragment>
  );
};

export default TagInstallationInstructionsModal;
