import React, { createContext, useContext, useEffect, useState } from "react";
import {
  formatCurrency2SigFig,
  formatWholeNumber,
} from "../../../../utils/campaigns";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";
import moment from "moment";
import { randomColors } from "./SEOTrafficContext";

const SEOConversionsContext = createContext();

export const SEOConversionsProvider = ({ reportKey, children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  const [startDateEnDate, setStartDateEnDate] = useState([]);

  const [conversionsSummary, setConversionsSummary] = useState([]);
  const [conversionsByDate, setConversionsByDate] = useState([]);
  const [conversionsBySource, setConversionsBySource] = useState([]);
  const [keyEventsByTraffic, setKeyEventsByTraffic] = useState([]);
  const [revenueByTraffic, setRevenueByTraffic] = useState([]);
  const [revenueByTrafficSummary, setRevenueByTrafficSummary] = useState([]);
  const [mediums, setMediums] = useState([]);

  const generateRevenueByTrafficSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Revenue",
          value: formatCurrency2SigFig(totals.revenue),
          prevValue: 0, //Prev Values are temporary not supported will apply later as future improvements
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  const processKeyEventsByTraffic = (data, startDate, endDate) => {
    if (!data || !data.keyEventsByTrafficReport) return [];

    const filteredEvents = data.keyEventsByTrafficReport.byDate
      .filter(item => {
        const date = moment(item.date);
        return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
      })
      .flatMap(item => item.events);

    const eventSummary = {};

    filteredEvents.forEach(event => {
      if (!eventSummary[event.event]) {
        eventSummary[event.event] = {
          eventName: event.event,
          total: 0,
          organic: 0,
          none: 0,
          referral: 0,
          cpc: 0,
          notSet: 0,
        };
      }

      eventSummary[event.event].total += event.totalCount;

      event.mediums.forEach(medium => {
        switch (medium.medium) {
          case "organic":
            eventSummary[event.event].organic += medium.eventCount;
            break;
          case "(none)":
            eventSummary[event.event].none += medium.eventCount;
            break;
          case "referral":
            eventSummary[event.event].referral += medium.eventCount;
            break;
          case "cpc":
            eventSummary[event.event].cpc += medium.eventCount;
            break;
          case "(not set)":
            eventSummary[event.event].notSet += medium.eventCount;
            break;
          default:
            break;
        }
      });
    });

    return Object.values(eventSummary);
  };

  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            const json = await response.json();
            setReportData(json);
          } catch (err) {
            console.error(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  useEffect(() => {
    if (reportData) {
      const { conversionsReport, revenueByTrafficSourceReport } = reportData;
      const { totals, byDate, bySource } = conversionsReport;

      const endDate = moment.max(byDate.map(d => moment(d.date, "YYYY-MM-DD")));
      const startDate = endDate.clone().subtract(29, "days");

      setConversionsSummary(generateConversionsSummary(totals));
      setConversionsByDate(
        byDate
          .filter(d => moment(d.date, "YYYY-MM-DD").isSameOrAfter(startDate))
          .sort((a, b) =>
            moment(a.date, "YYYY-MM-DD").diff(moment(b.date, "YYYY-MM-DD"))
          )
      );
      setConversionsBySource(bySource);

      const processedKeyEvents = processKeyEventsByTraffic(
        reportData,
        startDate,
        endDate
      );
      setKeyEventsByTraffic(processedKeyEvents);

      setRevenueByTrafficSummary(
        generateRevenueByTrafficSummary(revenueByTrafficSourceReport.total)
      );
      let mediums = [];
      revenueByTrafficSourceReport.byDate.forEach((d, index) => {
        d.source.forEach(s => {
          const mediumExists = mediums.findIndex(m => m.medium === s.medium);
          if (mediumExists === -1) {
            mediums.push({
              medium: s.medium,
              color: randomColors(),
            });
          }
        });
      });
      setMediums(mediums);
      setRevenueByTraffic(
        revenueByTrafficSourceReport.byDate
          .filter(b =>
            moment(b.date, "YYYY-MM-DD").isSameOrAfter(
              moment().subtract(1, "year")
            )
          )
          .map(b => {
            const { source } = b;
            const value = {
              date: b.date,
              revenue: b.revenue,
            };

            source.forEach(m => {
              value[m.medium] = {
                revenue: Number(m.revenue),
              };
            });
            return value;
          })
      );

      setStartDateEnDate([startDate, endDate]);
      setReportLoading(false);
    }
  }, [reportData]);

  const generateConversionsSummary = totals => {
    return {
      visible: true,
      values: [
        {
          title: "Total Conversions",
          value: formatWholeNumber(totals.conversions),
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "Total Users",
          value: formatWholeNumber(totals.totalUsers),
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
        {
          title: "User Engagement",
          value: totals.userEngagement,
          prevValue: 0,
          prevPercentage: 0,
          prevRanges: null,
        },
      ],
    };
  };

  const secondsToFormatted = seconds => {
    const days = Math.floor(seconds / 86400);
    const remainingSeconds = seconds % 86400;
    const time = moment.utc(remainingSeconds * 1000).format("HH:mm:ss");
    return days > 0 ? `${days} day(s) ${time}` : time;
  };

  const onDateChange = dates => {
    if (!reportData) return;

    const { conversionsReport } = reportData;
    const { byDate } = conversionsReport;
    const [startDate, endDate] = dates.map(d => moment(d));

    let totalsFiltered = {
      conversions: 0,
      totalUsers: 0,
      userEngagementSeconds: 0,
      userEngagement: "00:00:00",
    };
    let bySourceFiltered = [];
    const byDateFiltered = byDate.filter(d => {
      const date = moment(d.date, "YYYY-MM-DD");
      return date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
    });

    byDateFiltered.forEach(c => {
      totalsFiltered.conversions += Number(c.conversions);
      totalsFiltered.totalUsers += Number(c.totalUsers);
      totalsFiltered.userEngagementSeconds += Number(c.userEngagementSeconds);

      c.source.forEach(sm => {
        const sourceExists = bySourceFiltered.findIndex(
          s => s.trafficSource === sm.trafficSource
        );
        if (sourceExists === -1) {
          bySourceFiltered.push({
            trafficSource: sm.trafficSource,
            conversions: Number(sm.conversions),
            totalUsers: Number(sm.totalUsers),
            newUsers: Number(sm.newUsers),
            userEngagementSeconds: Number(c.userEngagementSeconds),
            userEngagement: secondsToFormatted(Number(c.userEngagementSeconds)),
          });
        } else {
          bySourceFiltered[sourceExists].conversions += Number(sm.conversions);
          bySourceFiltered[sourceExists].totalUsers += Number(sm.totalUsers);
          bySourceFiltered[sourceExists].newUsers += Number(sm.newUsers);
          bySourceFiltered[sourceExists].userEngagementSeconds += Number(
            c.userEngagementSeconds
          );
          bySourceFiltered[sourceExists].userEngagement = secondsToFormatted(
            Number(bySourceFiltered[sourceExists].userEngagementSeconds)
          );
        }
      });
    });
    totalsFiltered.userEngagement = secondsToFormatted(
      totalsFiltered.userEngagementSeconds
    );

    const processedKeyEvents = processKeyEventsByTraffic(
      reportData,
      startDate,
      endDate
    );
    setKeyEventsByTraffic(processedKeyEvents);
    setConversionsByDate(byDateFiltered);
    setConversionsBySource(bySourceFiltered);
    setConversionsSummary(generateConversionsSummary(totalsFiltered));
    setStartDateEnDate(dates);
    setReportLoading(false);
  };

  return (
    <SEOConversionsContext.Provider
      value={{
        conversionsSummary,
        conversionsByDate,
        reportLoading,
        conversionsBySource,
        secondsToFormatted,
        reportError,
        startDateEnDate,
        onDateChange,
        keyEventsByTraffic,
        revenueByTraffic,
        revenueByTrafficSummary,
        mediums,
      }}
    >
      {children}
    </SEOConversionsContext.Provider>
  );
};

export const useSEOConversionsContext = () => {
  const context = useContext(SEOConversionsContext);
  if (!context) {
    throw new Error(
      "useSEOConversionsContext must be used within a SEOConversionsProvider"
    );
  }
  return context;
};
