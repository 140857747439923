import React from "react";
import { Table, Card } from "antd";
import { useSEOReferringDomainsContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOReferringDomainsContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";

const ReferringDomainsView = () => {
  const { reportData, reportLoading } = useSEOReferringDomainsContext();

  const columns = [
    {
      title: "Session Source",
      dataIndex: "source",
      key: "source",
      width: "20%",
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      align: "right",
    },
    {
      title: "Engaged sessions",
      dataIndex: "engagedSessions",
      key: "engagedSessions",
      align: "right",
    },
    {
      title: "Engagement rate",
      dataIndex: "engagementRate",
      key: "engagementRate",
      align: "right",
      render: text => `${text}%`,
    },
    {
      title: "Ave. Engagement time per session",
      dataIndex: "avgEngagementTime",
      key: "avgEngagementTime",
      align: "right",
      render: (_, record) => {
        return Number(record.userEngagementDuration) / Number(record.sessions);
      },
    },
    {
      title: "Events per session",
      dataIndex: "eventsPerSession",
      key: "eventsPerSession",
      align: "right",
    },
    {
      title: "Event count",
      dataIndex: "eventCount",
      key: "eventCount",
      align: "right",
    },
    {
      title: "Key Events",
      dataIndex: "keyEvents",
      key: "keyEvents",
      align: "right",
    },
    {
      title: "Key Events Rate",
      dataIndex: "sessionKeyEventRate",
      key: "sessionKeyEventRate",
      align: "right",
      render: (_, record) => {
        return `${Number(record.keyEvents) / Number(record.sessions)}%`;
      },
    },
  ];

  if (reportLoading) return <LoadingPage />;

  return (
    <Card>
      <Table
        columns={columns}
        dataSource={reportData.bySource}
        summary={pageData => {
          // Calculate totals
          const totalSessions = pageData.reduce(
            (sum, row) => sum + row.sessions,
            0
          );
          const totalEngagedSessions = pageData.reduce(
            (sum, row) => sum + row.engagedSessions,
            0
          );
          const totalEventCount = pageData.reduce(
            (sum, row) => sum + row.eventCount,
            0
          );
          const totalKeyEvents = pageData.reduce(
            (sum, row) => sum + row.keyEvents,
            0
          );

          return (
            <Table.Summary.Row>
              <Table.Summary.Cell>Total</Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {totalSessions}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {totalEngagedSessions}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {((totalEngagedSessions / totalSessions) * 100).toFixed(2)}%
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">-</Table.Summary.Cell>
              <Table.Summary.Cell align="right">-</Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {totalEventCount}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {totalKeyEvents}
              </Table.Summary.Cell>
              <Table.Summary.Cell align="right">
                {((totalEngagedSessions / totalSessions) * 100).toFixed(2)}%
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Card>
  );
};

export default ReferringDomainsView;
