import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import GET_SIGNED_URL from "../../../../GraphQl/Queries/GET_SIGNED_URL";

const SEOReferringDomainsContext = createContext();

export const SEOReferringDomainsProvider = ({ reportKey = "-", children }) => {
  const [reportData, setReportData] = useState();
  const [reportLoading, setReportLoading] = useState(true);
  const [reportError, setReportError] = useState();
  const { data: signedURL } = useQuery(GET_SIGNED_URL, {
    variables: {
      key: reportKey,
    },
  });

  useEffect(() => {
    if (signedURL && !reportData) {
      const s3Url = new URL(signedURL.getSignedURL);
      fetch(s3Url).then(async response => {
        if (response) {
          try {
            const json = await response.json();
            setReportData(json);
            setReportLoading(false);
          } catch (err) {
            console.error(err);
            setReportError(err);
            setReportLoading(false);
          }
        }
      });
    }
  }, [reportData, signedURL]);

  return (
    <SEOReferringDomainsContext.Provider
      value={{
        reportData,
        reportLoading,
        reportError,
      }}
    >
      {children}
    </SEOReferringDomainsContext.Provider>
  );
};

export const useSEOReferringDomainsContext = () => {
  const context = useContext(SEOReferringDomainsContext);
  if (!context) {
    throw new Error(
      "useSEOReferringDomainsContext must be used within a SEOReferringDomainsProvider"
    );
  }
  return context;
};
