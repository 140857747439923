import React, { useEffect, useState, useMemo } from "react";
import { Card, Col, Row, Table, Select, Space, Typography } from "antd";
import NewDetailBar from "../../components/NewDetailBar";
import DonutChart from "../../components/DonutChart";
import TrafficByChannelLineChart from "../../components/TrafficByChannelLineChart";
import SessionsChart from "../../components/TrafficSessionsChart";
import {
  KPITitleText,
  KpiSubText,
} from "../../../../../shared/globalStyling/styledText";
import { useSEOTrafficContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOTrafficContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";
import TrafficSourceModal from "../../components/TrafficSourceModal";
import {
  formatNumber2SigFig,
  formatWholeNumber,
} from "../../../../../../core/utils/campaigns";
import moment from "moment";

const { Option } = Select;
const { Text } = Typography;

const TrafficView = ({
  domain,
  selectedDate,
  setSelectedDate,
  setReportData,
}) => {
  const {
    reportLoading,
    topLandingPages,
    topCities,
    newVsReturning,
    trafficByChannelByDate,
    mediums,
    sessions,
    sessionsByDate,
    startDateEnDate,
    onDateChange,
    pageViews,
    pageViewsByDate,
  } = useSEOTrafficContext();
  const [initialLoad, setInitialLoad] = useState(true);
  const [breakdownBy, setBreakdownBy] = useState("sessions");
  const [trafficSources, setTrafficSources] = useState({});
  const [filteredTrafficData, setFilteredTrafficData] = useState([]);
  const [combineLowTraffic, setCombineLowTraffic] = useState(false);
  const [visibleSources, setVisibleSources] = useState([]);

  useEffect(() => {
    if (mediums.length > 0) {
      const initialSources = {};
      mediums.forEach(medium => {
        initialSources[medium.medium] = { visible: true, color: medium.color };
      });
      setTrafficSources(initialSources);
    }
  }, [mediums]);

  useEffect(() => {
    if (
      trafficByChannelByDate.length > 0 &&
      Object.keys(trafficSources).length > 0
    ) {
      updateFilteredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trafficByChannelByDate, trafficSources, combineLowTraffic]);

  const updateFilteredData = () => {
    if (
      !filteredTrafficByChannelSummary ||
      !filteredTrafficByChannelSummary.values
    ) {
      setFilteredTrafficData([]);
      setVisibleSources([]);
      return;
    }

    let filteredData = trafficByChannelByDate.map(day => {
      const filteredDay = { date: day.date };
      let dayOtherSources = { sessions: 0, pageViews: 0 };

      Object.keys(day).forEach(source => {
        if (source !== "date") {
          const sourceData = filteredTrafficByChannelSummary.values.find(
            item => item.title === source
          );
          if (sourceData) {
            filteredDay[source] = day[source];
          } else if (combineLowTraffic) {
            dayOtherSources.sessions += day[source].sessions || 0;
            dayOtherSources.pageViews += day[source].pageViews || 0;
          }
        }
      });

      if (
        combineLowTraffic &&
        (dayOtherSources.sessions > 0 || dayOtherSources.pageViews > 0)
      ) {
        filteredDay["Other Sources"] = dayOtherSources;
      }

      return filteredDay;
    });

    setFilteredTrafficData(filteredData);

    const visibleSources = filteredTrafficByChannelSummary.values.map(
      item => item.title
    );
    setVisibleSources(visibleSources);
  };

  useEffect(() => {
    if (trafficByChannelByDate.length > 0) {
      updateFilteredData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combineLowTraffic, breakdownBy, trafficByChannelByDate]);

  const handleTrafficSourceUpdate = (updatedSources, newCombineLowTraffic) => {
    setTrafficSources(updatedSources);
    setCombineLowTraffic(newCombineLowTraffic);
  };

  const filteredTrafficByChannelSummary = useMemo(() => {
    if (!trafficByChannelByDate || trafficByChannelByDate.length === 0) {
      return { visible: false, values: [] };
    }

    const aggregated = trafficByChannelByDate.reduce((acc, day) => {
      Object.entries(day).forEach(([key, value]) => {
        if (key !== "date") {
          if (!acc[key]) {
            acc[key] = { sessions: 0, pageViews: 0 };
          }
          acc[key].sessions += value.sessions;
          acc[key].pageViews += value.pageViews;
        }
      });
      return acc;
    }, {});

    let totalTraffic = Object.values(aggregated).reduce((sum, item) => {
      return sum + (Number(item[breakdownBy]) || 0);
    }, 0);

    let filteredValues = [];
    let otherSources = {
      title: "Other Sources",
      value: 0,
      sessions: 0,
      pageViews: 0,
    };

    Object.entries(aggregated).forEach(([source, data]) => {
      if (trafficSources[source] && trafficSources[source].visible) {
        const value = Number(data[breakdownBy]) || 0;
        const percentage = (value / totalTraffic) * 100;

        if (!combineLowTraffic || percentage >= 5) {
          filteredValues.push({
            title: source,
            value: formatWholeNumber(value),
            sessions: data.sessions,
            pageViews: data.pageViews,
            percentage: percentage,
          });
        } else {
          otherSources.value += value;
          otherSources.sessions += data.sessions;
          otherSources.pageViews += data.pageViews;
        }
      }
    });

    if (combineLowTraffic && otherSources.value > 0) {
      otherSources.value = formatWholeNumber(otherSources.value);
      otherSources.percentage = (otherSources.value / totalTraffic) * 100;
      filteredValues.push(otherSources);
    }

    // Sort the filtered values by traffic volume (descending)
    filteredValues.sort((a, b) => b[breakdownBy] - a[breakdownBy]);

    return {
      visible: true,
      values: filteredValues,
    };
  }, [trafficByChannelByDate, trafficSources, combineLowTraffic, breakdownBy]);

  useEffect(() => {
    if (
      selectedDate &&
      selectedDate.length > 0 &&
      startDateEnDate.length > 0 &&
      (!selectedDate[0].isSame(startDateEnDate[0]) ||
        !selectedDate[1].isSame(startDateEnDate[1])) &&
      !reportLoading
    ) {
      onDateChange(selectedDate);
    }
  }, [onDateChange, reportLoading, selectedDate, startDateEnDate]);

  useEffect(() => {
    if (
      !reportLoading &&
      trafficByChannelByDate &&
      trafficByChannelByDate.length > 0 &&
      initialLoad
    ) {
      setSelectedDate([
        moment(trafficByChannelByDate[0].date),
        moment(trafficByChannelByDate[trafficByChannelByDate.length - 1].date),
      ]);

      const aggregateTrafficData = data => {
        const aggregated = data.reduce((acc, day) => {
          Object.entries(day).forEach(([key, value]) => {
            if (key !== "date") {
              if (!acc[key]) {
                acc[key] = { sessions: 0, pageViews: 0 };
              }
              acc[key].sessions += value.sessions;
              acc[key].pageViews += value.pageViews;
            }
          });
          return acc;
        }, {});

        return Object.entries(aggregated)
          .map(([source, data]) => ({
            "Traffic Source": source,
            "Total Sessions": formatWholeNumber(data.sessions),
            "Total Page Views": formatWholeNumber(data.pageViews),
            "Pages / Session": formatNumber2SigFig(
              data.pageViews / data.sessions
            ),
          }))
          .sort(
            (a, b) =>
              parseInt(b["Total Sessions"].replace(/,/g, "")) -
              parseInt(a["Total Sessions"].replace(/,/g, ""))
          );
      };

      const aggregatedData = aggregateTrafficData(trafficByChannelByDate);
      setReportData(aggregatedData);

      setInitialLoad(false);
    }
  }, [
    initialLoad,
    reportLoading,
    trafficByChannelByDate,
    setReportData,
    setSelectedDate,
  ]);

  useEffect(() => {
    if (
      trafficByChannelByDate &&
      trafficByChannelByDate.length > 0 &&
      !initialLoad
    ) {
      const aggregateTrafficData = data => {
        const aggregated = data.reduce((acc, day) => {
          Object.entries(day).forEach(([key, value]) => {
            if (key !== "date") {
              if (!acc[key]) {
                acc[key] = { sessions: 0, pageViews: 0 };
              }
              acc[key].sessions += value.sessions;
              acc[key].pageViews += value.pageViews;
            }
          });
          return acc;
        }, {});

        return Object.entries(aggregated)
          .map(([source, data]) => ({
            "Traffic Source": source,
            "Total Sessions": formatWholeNumber(data.sessions),
            "Total Page Views": formatWholeNumber(data.pageViews),
            "Pages / Session": formatNumber2SigFig(
              data.pageViews / data.sessions
            ),
          }))
          .sort(
            (a, b) =>
              parseInt(b["Total Sessions"].replace(/,/g, "")) -
              parseInt(a["Total Sessions"].replace(/,/g, ""))
          );
      };

      const aggregatedData = aggregateTrafficData(trafficByChannelByDate);
      setReportData(aggregatedData);
    }
  }, [initialLoad, trafficByChannelByDate, setReportData]);

  const topLandingPagesColumns = [
    {
      title: "Page URL",
      dataIndex: "landingPage",
      key: "landingPage",
      width: "70%",
      sorter: (a, b) => a.landingPage.localeCompare(b.landingPage),
      render: text => (domain ? domain + text : text),
    },
    {
      title: "Page Views",
      dataIndex: "screenPageViews",
      key: "screenPageViews",
      sorter: (a, b) => a.screenPageViews - b.screenPageViews,
      render: text => {
        const formatNumber = num => {
          if (num >= 1000) {
            return (num / 1000).toFixed(1) + "K";
          }
          return num.toString();
        };

        return formatNumber(text);
      },
    },
  ];

  const topCitiesColumns = [
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: "70%",
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      sorter: (a, b) => a.sessions - b.sessions,
      render: text => {
        const formatNumber = num => {
          if (num >= 1000) {
            return (num / 1000).toFixed(1) + "K";
          }
          return num.toString();
        };

        return formatNumber(text);
      },
    },
  ];

  const calculateTotal = data => {
    if (!data || !data.values) return 0;
    return data.values.reduce((total, item) => {
      return total + Number(item.value.replace(/,/g, ""));
    }, 0);
  };

  if (reportLoading) return <LoadingPage />;
  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Card
            title={"TRAFFIC BY SOURCE"}
            extra={
              <Space>
                <Text>Breakdown by:</Text>
                <Select
                  value={breakdownBy}
                  style={{ width: 120 }}
                  onChange={value => setBreakdownBy(value)}
                >
                  <Option value="sessions">Sessions</Option>
                  <Option value="pageViews">Page Views</Option>
                </Select>
                <TrafficSourceModal
                  onUpdate={handleTrafficSourceUpdate}
                  initialSources={trafficSources}
                  initialCombineLowTraffic={combineLowTraffic}
                />
              </Space>
            }
          >
            <NewDetailBar
              dataToDisplay={filteredTrafficByChannelSummary}
              title={
                breakdownBy === "pageViews"
                  ? "Total Page Views"
                  : "Total Sessions"
              }
              total={formatWholeNumber(
                calculateTotal(filteredTrafficByChannelSummary)
              )}
            />
            <TrafficByChannelLineChart
              data={filteredTrafficData}
              mediums={mediums}
              breakdownBy={breakdownBy}
              visibleSources={visibleSources}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={8}>
              <Card
                title={"NEW VS RETURNING VISITORS"}
                style={{ height: "100%" }}
              >
                <Row>
                  <Col span={12}>
                    <div style={{ marginBottom: "20px" }}>
                      <KPITitleText text={"NEW"} />
                      <KpiSubText
                        text={formatWholeNumber(newVsReturning.new)}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ marginBottom: "20px" }}>
                      <KPITitleText text={"RETURNING"} />
                      <KpiSubText
                        text={formatWholeNumber(newVsReturning.returning)}
                      />
                    </div>
                  </Col>
                </Row>
                <DonutChart
                  chartData={[
                    {
                      value: "New",
                      counts: newVsReturning.new,
                    },
                    {
                      value: "Returning",
                      counts: newVsReturning.returning,
                    },
                  ]}
                  showLegend={true}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card title={"SESSIONS"} style={{ height: "100%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <KPITitleText text={"SESSIONS"} />
                  <KpiSubText text={formatWholeNumber(sessions)} />
                </div>
                <SessionsChart data={sessionsByDate} />
              </Card>
            </Col>
            <Col span={8}>
              <Card title={"PAGE VIEWS"} style={{ height: "100%" }}>
                <div style={{ marginBottom: "20px" }}>
                  <KPITitleText text={"PAGE VIEWS"} />
                  <KpiSubText text={formatWholeNumber(pageViews)} />
                </div>
                <SessionsChart data={pageViewsByDate} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={16}>
            <Col span={12}>
              <Card title={"TOP LANDING PAGES"} style={{ height: "100%" }}>
                <Table
                  style={{ width: "100%" }}
                  columns={topLandingPagesColumns}
                  dataSource={topLandingPages}
                  size={"default"}
                  scroll={{ x: 300 }}
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card title={"TOP CITIES"}>
                <Table
                  style={{ width: "100%" }}
                  columns={topCitiesColumns}
                  dataSource={topCities}
                  size={"default"}
                  scroll={{ x: 300 }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TrafficView;
