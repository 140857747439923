import React from "react";

const TagsManagerController = ({
  orgs,
  currentOrgId,
  createEventTag,
  carts,
  ...props
}) => {
  const advertisers = orgs.orgs.map(org => {
    return { value: org.id, label: org.name };
  });

  const onSubmit = async values => {
    try {
      createEventTag({
        variables: {
          data: {
            name: values.name,
            orgId: values.advertiser,
            isEcommerce: values.ecommerce,
            cart: values.platform,
            isSignUps: values.signups,
            domain: values.domain,
            appId: values.appId,
            dspTracking: values.dspTracking,
            orgAdvertiserId: values.orgAdvertiserId,
            thirdPartyTag: values.thirdPartyTag,
          },
        },
      });
    } catch (error) {
      console.error("Error creating event tag:", error);
    }
  };

  return React.cloneElement(props.children, {
    ...props.children.props,
    advertisers,
    currentOrgId,
    carts: carts.getCarts,
    onSubmit,
    orgs,
  });
};

export default TagsManagerController;
