import React, { useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Col, Typography } from "antd";
import TagInstallationInstructionsModal from "../../details/components/TagInstallationInstructionsModal";

const DocumentationAlert = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <Col xs={23} lg={23} style={{ display: "inline-block" }}>
        <Alert
          description={
            <>
              <h4>
                <InfoCircleOutlined
                  style={{ color: "#1890ff", marginRight: "5px" }}
                />
                Please ensure that the tags below are installed on the{" "}
                <a
                  style={{ color: "#1890ff", cursor: "pointer" }}
                  href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/header"
                  target={"_blank"}
                >
                  header section{" "}
                </a>
                on all pages of your website. Click{" "}
                <Typography.Link onClick={() => setIsModalVisible(true)}>
                  here
                </Typography.Link>{" "}
                for more comprehensive instructions.
              </h4>
            </>
          }
        />
        <TagInstallationInstructionsModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </Col>
    </>
  );
};

export default DocumentationAlert;
