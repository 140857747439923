import {
  Card,
  Col,
  Input,
  Row,
  Radio,
  Space,
  Table,
  Typography,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import DetailBar from "../../components/DetailBar";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DesktopOutlined,
  MobileOutlined,
  MonitorOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { PrimaryTableRowText } from "../../../../../shared/globalStyling/styledText";
import { useSEOKeywordContext } from "../../../../../../core/components/seoRefactored/seoDetails/context/SEOKeywordsContext";
import { LoadingPage } from "../../../../../shared/LoadingPage";

const { Text } = Typography;
const { Option } = Select;

const RankImproveIcon = (currentRank, compareRank) => {
  if (currentRank === compareRank) {
    return compareRank;
  } else if (currentRank < compareRank) {
    const diff = Math.abs(currentRank - compareRank);
    return (
      <Space>
        {compareRank}
        <Text type="success">
          <ArrowUpOutlined />
          {Math.floor(diff)}
        </Text>
      </Space>
    );
  } else {
    const diff = Math.abs(compareRank - currentRank);
    return (
      <Space>
        {compareRank}
        <Text type="danger">
          <ArrowDownOutlined />
          {Math.floor(diff)}
        </Text>
      </Space>
    );
  }
};

const KeywordRankingsView = ({ setReportData }) => {
  const {
    reportData,
    reportLoading,
    summaryDetails,
    keywordTableRankings,
    selectedDevice,
    setSelectedDevice,
    onSearchKeywordRankings,
    resetKeywordTableRankings,
    filterTop,
  } = useSEOKeywordContext();

  const [searchType, setSearchType] = useState("include");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (keywordTableRankings && keywordTableRankings.length > 0) {
      const data = keywordTableRankings.map(k => {
        const baseData = {
          Keyword: k.keyword,
          "Current Rank": k.currentRank ? k.currentRank : "-",
          "Previous Week": k.lastWeekRank ? k.lastWeekRank : "-",
          "Previous Month": k.lastMonthRank ? k.lastMonthRank : "-",
        };

        // Conditionally include device-specific ranks
        if (selectedDevice === "all") {
          baseData.Desktop = k.desktopRank ? k.desktopRank : "-";
          baseData.Mobile = k.mobileRank ? k.mobileRank : "-";
          baseData.Tablet = k.tabletRank ? k.tabletRank : "-";
        }
        return baseData;
      });

      setReportData(data);
    }
  }, [keywordTableRankings, setReportData, selectedDevice]);

  if (reportLoading) return <LoadingPage />;
  if (!reportData) {
    return <div>No rankings data available</div>;
  }

  let columns = [
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
      render: text => <PrimaryTableRowText text={text} />,
      sorter: (a, b) => a.keyword.localeCompare(b.keyword),
    },
    {
      title: "Current Rank",
      dataIndex: "currentRank",
      key: "currentRank",
      render: text => {
        return text ? Math.floor(text) : "-";
      },
      sorter: (a, b) => a.currentRank - b.currentRank,
    },
    {
      title: "Previous Week",
      dataIndex: "lastWeekRank",
      key: "lastWeekRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? (
          <>{RankImproveIcon(Math.floor(currentRank), Math.floor(text))}</>
        ) : (
          "-"
        );
      },
      sorter: (a, b) => a.lastWeekRank - b.lastWeekRank,
    },
    {
      title: "Previous Month",
      dataIndex: "lastMonthRank",
      key: "lastMonthRank",
      render: (text, record) => {
        const { currentRank } = record;
        return text ? (
          <>{RankImproveIcon(Math.floor(currentRank), Math.floor(text))}</>
        ) : (
          "-"
        );
      },
      sorter: (a, b) => a.lastMonthRank - b.lastMonthRank,
    },
  ];

  if (selectedDevice === "all") {
    columns = [
      ...columns,
      {
        title: "Desktop",
        dataIndex: "desktopRank",
        key: "desktopRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? (
            <>{RankImproveIcon(Math.floor(currentRank), Math.floor(text))}</>
          ) : (
            "-"
          );
        },
      },
      {
        title: "Mobile",
        dataIndex: "mobileRank",
        key: "mobileRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? (
            <>{RankImproveIcon(Math.floor(currentRank), Math.floor(text))}</>
          ) : (
            "-"
          );
        },
      },
      {
        title: "Tablet",
        dataIndex: "tabletRank",
        key: "tabletRank",
        render: (text, record) => {
          const { currentRank } = record;
          return text ? (
            <>{RankImproveIcon(Math.floor(currentRank), Math.floor(text))}</>
          ) : (
            "-"
          );
        },
      },
    ];
  }

  const handleSearch = value => {
    setSearchValue(value);
    if (value) {
      onSearchKeywordRankings(value, searchType);
    } else {
      resetKeywordTableRankings();
    }
  };

  return (
    <React.Fragment>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <DetailBar dataToDisplay={summaryDetails} />
        </Col>
        <Col xs={24}>
          <Card
            title={"Top Google Keyword Positions"}
            extra={
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <Select
                    defaultValue="all"
                    onChange={value => filterTop(value)}
                  >
                    <Option value="10">Top 10</Option>
                    <Option value="20">Top 20</Option>
                    <Option value="30">Top 30</Option>
                    <Option value="40">Top 40</Option>
                    <Option value="50">Top 50</Option>
                    <Option value="all">Show All</Option>
                  </Select>
                </Col>
                <Col>
                  <Radio.Group
                    value={selectedDevice}
                    onChange={e => {
                      const device = e.target.value;
                      setSelectedDevice(device);
                    }}
                  >
                    <Radio.Button value="all">
                      <Space>
                        <MonitorOutlined />
                        All Device
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="desktop">
                      <Space>
                        <DesktopOutlined />
                        Desktop
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="mobile">
                      <Space>
                        <MobileOutlined />
                        Mobile
                      </Space>
                    </Radio.Button>
                    <Radio.Button value="tablet">
                      <Space>
                        <TabletOutlined />
                        Tablet
                      </Space>
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col flex="auto">
                  <Input.Group compact>
                    <Select
                      defaultValue="include"
                      style={{ width: "30%" }}
                      onChange={value => setSearchType(value)}
                    >
                      <Option value="include">Include</Option>
                      <Option value="exclude">Exclude</Option>
                    </Select>
                    <Input.Search
                      style={{ width: "70%" }}
                      placeholder="Search keywords"
                      value={searchValue}
                      onChange={e => setSearchValue(e.target.value)}
                      onSearch={handleSearch}
                      allowClear
                    />
                  </Input.Group>
                </Col>
              </Row>
            }
          >
            <Table
              rowKey={row => row.keyword}
              columns={columns}
              dataSource={keywordTableRankings}
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KeywordRankingsView;
